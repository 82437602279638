import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import {
  Section,
  CampaignSection,
  SectionWithTextAndButton,
  PricesSection,
  ContactSection,
  TeaserSection,
  BaseContentSection,
  findNamedBacgroundAsset,
  PreviewSectionPictureBelowTitle,
  PreviewSection,
  EventPreviewSectionProps,
  EventPreviewSection,
} from '../components/section';
import { HeaderText1 } from '../components/text';
import { PreviewCardWrapper } from '../components/card';
import { NavigationLink } from '../components/link';
import { PAGES_INFO, TEMPLATES_INFO } from '../utils/pages-info';
import { WHITE } from '../utils/color-constants';
import createTranslate from '../utils/translator-provider';
import { getPageItemGroup, getPageItemGroupItems } from '../utils/gatsby-types';
import {ItemGroup, ItemGroupWithHeaderAndBodyText} from '../templates/item-group'

export default ({ data }) => {
  const translate = createTranslate(data?.page?.translations);

  const contentBackgroundAsset = findNamedBacgroundAsset("Background", data?.page?.sectionBackgrounds);

  const eventGroup = getPageItemGroup(data.page, "Landing Page Events");
  const previewList = getPageItemGroupItems(data.page, "Preview");
  const rightItems = getPageItemGroupItems(data.page, "Right Down");
  const rightDown = rightItems.length === 1 ? rightItems[0] : undefined

  return (
    <Layout
      title={data.page.title}
      description={data.page.seoDescription}
      path={PAGES_INFO.landingPage.path}
      fixedBackground={contentBackgroundAsset?.asset}
    >
      <TeaserSection
        backgroundAssets={data?.page?.sectionBackgrounds}
        sectionName={'Teaser'}
        title={translate('landingPage.teaserSection.title')}
      />

      <BaseContentSection>
        {/** Left column 2/3 size */}
        <div className="col-span-1 md:col-span-2 flex flex-col gap-10 px-12 md:px-0">
          <ItemGroup itemGroup={previewList}/>
        </div>

        {/** md spacer */}
        <div className="md:hidden col-span-1 h-12"/>

        {/** Right column 1/3 size */}
        <div>
          <h2 className="text-center">{eventGroup.title}</h2>

          <br/>

          <div className="flex flex-col sm:flex-row lg:flex-col gap-10">
            <ItemGroup itemGroup={eventGroup.items} />
            {rightDown ? <PreviewSectionPictureBelowTitle imageAsset={rightDown.image} title={rightDown.title} lead={rightDown.predescription.predescription} linkUrl={rightDown.link} linkText={rightDown.linkText} /> : null}
          </div>
        </div>
      </BaseContentSection>


      {/* <CampaignSectionWrapper translate={translate} data={data} />
      <NewAdvertiserSection translate={translate} data={data} />
      <ShowRoomSection translate={translate} data={data} />
      <PricesSectionWrapper translate={translate} data={data} />
      <CurrentSection translate={translate} data={data} /> */}
      {/* <ContactSection translate={translate} data={data} /> */}
    </Layout>
  );
};

const CampaignSectionWrapper = ({ data, translate }) => {

  let campaignData = undefined
  const contentfulCampaign = 'ContentfulCampaign'

  const campaignDataExists = data && data.page
    && data.page.itemGroups

  if (campaignDataExists) {
    campaignData = data.page.itemGroups[0].items[0].items
  } else {
    return null;
  }

  return (
    <>
      {campaignData.map( (cd, index) => {
        if(index > 0 ) {
          return(
           <SmallCampaignSection
              // id={`campaign-section-${index}`}
              backgroundAssets={cd.internal.type === contentfulCampaign
                ? [cd.previewBackgroundAsset]
                : [cd.landingPagePreviewImage]
              }
              title={cd.internal.type === contentfulCampaign
                ? cd.header
                : cd.title
              }
              body={cd.internal.type === contentfulCampaign
                ? cd.previewText.previewText
                : cd.landingPagePreviewText.landingPagePreviewText
              }
              linkTo={cd.internal.type === contentfulCampaign
                ? `${TEMPLATES_INFO.campaign.path}${cd.slug}`
                : `${TEMPLATES_INFO.article.path}${cd.slug}`
              }
              linkText={cd.internal.type === contentfulCampaign
                ? cd.previewLinkText
                : cd.landingPagePreviewLinkText
              }
            />
          )
        }
        return(
          <CampaignSection
            // id={`campaign-section-${index}`}
            backgroundAssets={cd.internal.type === contentfulCampaign
              ? [cd.previewBackgroundAsset]
              : [cd.landingPagePreviewImage]
            }
            title={cd.internal.type === contentfulCampaign
              ? cd.header
              : cd.title
            }
            body={cd.internal.type === contentfulCampaign
              ? cd.previewText.previewText
              : cd.landingPagePreviewText.landingPagePreviewText
            }
            linkTo={cd.internal.type === contentfulCampaign
              ? `${TEMPLATES_INFO.campaign.path}${cd.slug}`
              : `${TEMPLATES_INFO.article.path}${cd.slug}`
            }
            linkText={cd.internal.type === contentfulCampaign
              ? cd.previewLinkText
              : cd.landingPagePreviewLinkText
            }
          />
        )
      })}
    </>
  );
};

const SmallCampaignSection = ({
  backgroundAssets,
  title,
  body,
  linkTo,
  linkText,
  }) => {
  return (
    <SectionWithTextAndButton
      backgroundAssets={backgroundAssets}
      title={title}
      body={body}
      linkTo={linkTo}
      linkText={linkText}
      className={'py-12 sm:py-32 bg-slate'}
    />
  )
}

const NewAdvertiserSection = ({ data, translate }) => {
  return (
    <SectionWithTextAndButton
      backgroundAssets={data?.page?.sectionBackgrounds}
      sectionName={'Landing page new advertiser section'}
      title={translate('landingPage.newAdvertiserSection.title')}
      body={translate('landingPage.newAdvertiserSection.body')}
      linkTo={"#TODO"}
      linkText={translate('landingPage.newAdvertiserSection.link')}
      className={'py-12 sm:py-32 bg-slate'}
    />
  );
};

const ShowRoomSection = ({ translate, data }) => {
  return (
    <SectionWithTextAndButton
      backgroundAssets={data?.page?.sectionBackgrounds}
      sectionName={'Landing page showroom section'}
      title={translate('landingPage.showRoomSection.title')}
      body={translate('landingPage.showRoomSection.body')}
      linkTo={"#TODO"}
      linkText={translate('landingPage.showRoomSection.link')}
      className={'py-12 sm:py-32 bg-grey-cool'}
    />
  );
};

const PricesSectionWrapper = ({ translate, data }) => {
  return (
    <PricesSection
      backgroundAssets={data?.page?.sectionBackgrounds}
      sectionName={'Landing page prices section'}
      title={translate('landingPage.pricesSection.title')}
      body={translate('landingPage.pricesSection.body')}
      linkText={translate('landingPage.pricesSection.link')}
    />
  );
};

const CurrentSection = ({ translate, data }) => {
  const articles = data.articles.nodes.map(c => {
    return {
      ...c,
      pathPrefix: TEMPLATES_INFO.article.path,
    };
  });

  const references = data.references.nodes.map(r => {
    return {
      ...r,
      pathPrefix: TEMPLATES_INFO.reference.path,
    };
  });

  const currentItems = [...articles, ...references];
  const linkStyle = 'font-semi-bold text-left uppercase text-sm text-white';

  return (
    <Section className={'py-12 sm:py-32 bg-slate-dark'}>
      <div className="relative w-full flex flex-col text-center items-center justify-between">
        <HeaderText1 color={WHITE}>
          {translate('landingPage.currentSection.title')}
        </HeaderText1>
        <PreviewCardWrapper items={currentItems} />
      </div>

      {articles.length > 0 ? (
        <div className="mt-16">
          <NavigationLink to={"#TODO"} className={linkStyle}>
            {translate('landingPage.currentSection.articlesCTALink')}
          </NavigationLink>
        </div>
      ) : null}
    </Section>
  );
};

export const query = graphql`
  query {
    page: contentfulPage(name: { eq: "Landing page" }) {
      title
      seoDescription
      sectionBackgrounds {
        name
        asset {
          file {
            contentType
            url
          }
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          fluid(maxWidth: 3000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        brightness
        useShadowForText
        objectPositionVertical
        objectPositionHorizontal
      }
      itemGroups {
        title
        header
        items {
          ... on ContentfulLiveEvent {
            date
            location
            city
            icon{
              file {
                contentType
                url
              }
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 3000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              fluid(maxWidth: 3000) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            background {
              title
              file {
                contentType
                url
              }
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 3000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              fluid(maxWidth: 3000) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            linkText
            linkUrl
          }
          ... on ContentfulPreviewContent {
                title
                predescription {
                  ... on contentfulPreviewContentPredescriptionTextNode {
                    predescription
                  }
                }
                image  {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                      fluid(maxWidth: 800) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                }
                linkText
                link
              }
          ... on ContentfulItemGroup {
            id
            header
            title
            items {
              ... on ContentfulPreviewContent {
                title
                predescription {
                  ... on contentfulPreviewContentPredescriptionTextNode {
                    predescription
                  }
                }
                image  {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                      fluid(maxWidth: 800) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                }
                linkText
                link
              }

              ... on ContentfulCampaign {
                id
                header
                previewText {
                  previewText
                }
                previewLinkText
                slug
                internal {
                  type
                }
                previewBackgroundAsset {
                  asset {
                    file {
                      contentType
                      url
                    }
                    localFile {
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 3000) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                    fluid(maxWidth: 3000) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                  }
                  brightness
                  useShadowForText
                  objectPositionVertical
                  objectPositionHorizontal
                }
              }
              ... on ContentfulArticle {
                tag
                title
                previewImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  fluid(maxWidth: 800) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
                landingPagePreviewText {
                  landingPagePreviewText
                }
                slug
                orderInLandingPage
              }
            }
          }
        }
      }
      translations {
        key
        value {
          value
        }
      }
    }
    articles: contentfulItemGroup(title: {eq: "Landing page articles"}) {
        header
        items {
          ... on ContentfulArticle {
          tag
          title
          previewImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          previewText
          slug
          orderInLandingPage
          landingPagePreviewLinkText
        }
        }
        title
    }
    articles: contentfulItemGroup(
     title: {eq: "Landing page articles"}
     ) {
        header
        items {
          ... on ContentfulArticle {
          tag
          title
          previewImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          previewText
          slug
          orderInLandingPage
        }
        }
        title
    }

    references: allContentfulReference(
      filter: { orderInLandingPage: { ne: null } }
      sort: { fields: orderInLandingPage, order: ASC }
    ) {
      nodes {
        tag
        title
        previewImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        previewText
        slug
        orderInLandingPage
      }
    }
  }
`;
